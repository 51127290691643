import React, { useState, useEffect } from 'react';
import SubscribeCompoents from "../../components/common/SubscribeCompoents";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { submitContactUsForm } from '../../services/get_a_quote';
import InputMask from 'react-input-mask';
import PageTitleComponent from '../../components/PageTitleComponent';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';
const ContactUs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const pid = (searchParams.size > 0) ? searchParams.get('pid') : "";
    const kw = (searchParams.size > 0) ? searchParams.get('kw') : "";
    if (pid && kw) {
        window.location.href = constant.ALARAM_SITEURL;
    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);
    const [isLoading, setLoading] = useState(false);
    const [initialValuesBefor, setInitialValuesBefor] = useState({ account_number: '', first_name: '', last_name: '', address: '', apt_suite: '', zip: '', email: '', phone_number: '', details: '', serviceType: '', terms_of_use: "" });

    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: initialValuesBefor,
        validationSchema: Yup.object({
            account_number: Yup.string().matches(/^[^a-zA-Z]{4,10}$/, "Account number must be between 3 and 10 digits"),
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            address: Yup.string().required('Required'),
            apt_suite: Yup.string(),
            zip: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone_number: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Required'),
            serviceType: Yup.string().required('Required'),
            details: Yup.string().required('Required')
        }),
        onSubmit: (values, { resetForm }) => {
            const objValue = {
                "AppNamespace": null,
                "AppSid": "",
                "AppVersion": "",
                "CheckVersion": null,
                "IpAddress": null,
                "Latitude": null,
                "Longitude": null,
                "AccountNumber": (values.account_number) ? values.account_number : null,
                "Address1": values.address,
                "Address2": (values.apt_suite) ? values.apt_suite : null,
                "ApptDate": null,
                "ApptTime": null,
                "AreaOfInterest": null,
                "Caller": "contactus",
                "City": null,
                "Comment": values.details,
                "Email": values.email,
                "EntryUser": null,
                "FirstName": values.first_name,
                "Keyword": Cookies.get('kw') ? Cookies.get('kw') : null,
                "LastName": values.last_name,
                "LeadSource": "",
                "LedgerType": values.serviceType,
                "Phone1": values.phone_number,
                "Phone2": null,
                "Pid": Cookies.get('pid') ? Cookies.get('pid') : null,
                "PromoCode": null,
                "ReferSource": null,
                "ResCom": "",
                "SalesRep": null,
                "SecurityIssue": null,
                "State": null,
                "SubSource": "",
                "WebTrack": null,
                "Xid": null,
                "Zip": values.zip,
                "AllowCallPermission": values.terms_of_use ? values.terms_of_use : false
            };
            submitContactUsForm(objValue).then(function (res) {
                const resp = res.data;
                setLoading(false);
                if (resp.Acknowledge >= 0) {
                    navigate('/thank-you');
                } else {
                    toast.error(resp.FullMessage !== 'undefined' ? resp.FullMessage : 'Validation error found!');
                }
            }).catch(err => {
                toast.error('Internal Server Error!' + err);
            });

            resetForm();
        }
    });
    return (
        <>
            <PageTitleComponent title={"Contact Us - Slomins"} />
            <main className="contact-page">
                <section className="hero_blk">
                    <div className="hero_inr container-xxl">
                        <div className="hero_content hero_box_outer">
                            <div className='hero_box'>
                                <h1>Contact Us</h1>
                                <p>Slomin's customer service is available 24/7 to help.</p>
                                <a className="white_btn" href="tel:18667566467"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28"></path>
                                </svg> 1-800-ALARM-ME</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<!-- contact-box block section starts here -->*/}
                <section className="contact-box-blk">
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="box-feature">
                                    <span className="box-feature-icon">
                                        <img src="../img/heating-cooling-icon.svg" alt="icon" />
                                    </span>
                                    <h5 className="bar">Home Heating &amp; Cooling</h5>
                                    <div className="subtext-phone">
                                        <a className="red-bdr-btn" href="tel:15169327000"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28"></path>
                                        </svg> 1-516-932-7000</a>
                                        <p>Monday-Friday, 8:00 AM to 4:00 PM EST</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="box-feature">
                                    <span className="box-feature-icon"><img src="../img/business-icon.svg" alt="icon" />
                                    </span>
                                    <h5 className="bar">Business Inquiries</h5>
                                    <div className="subtext-phone">
                                        <a className="red-bdr-btn" href="tel:18002527663"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M21.4998 16.4201V19.9561C21.4999 20.2093 21.4039 20.4531 21.2313 20.6383C21.0587 20.8235 20.8223 20.9364 20.5698 20.9541C20.1328 20.9841 19.7758 21.0001 19.4998 21.0001C10.6628 21.0001 3.49976 13.8371 3.49976 5.00012C3.49976 4.72412 3.51476 4.36712 3.54576 3.93012C3.56348 3.67757 3.67634 3.44113 3.86156 3.26853C4.04678 3.09593 4.29058 3.00001 4.54376 3.00012H8.07976C8.20379 3 8.32345 3.04598 8.41548 3.12914C8.50752 3.2123 8.56535 3.3267 8.57776 3.45012C8.60076 3.68012 8.62176 3.86312 8.64176 4.00212C8.84049 5.38905 9.24776 6.73795 9.84976 8.00312C9.94476 8.20312 9.88276 8.44212 9.70276 8.57012L7.54476 10.1121C8.86421 13.1866 11.3143 15.6367 14.3888 16.9561L15.9288 14.8021C15.9917 14.7141 16.0835 14.651 16.1883 14.6238C16.293 14.5965 16.4039 14.6069 16.5018 14.6531C17.7668 15.254 19.1153 15.6602 20.5018 15.8581C20.6408 15.8781 20.8238 15.9001 21.0518 15.9221C21.175 15.9348 21.2892 15.9927 21.3721 16.0847C21.4551 16.1767 21.4999 16.2962 21.4998 16.4201Z" fill="#B10D28"></path>
                                        </svg> 1-800-252-7663</a>
                                        <p>Monday-Friday, 8:00 AM to 4:00 PM EST</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="box-feature">
                                    <span className="box-feature-icon"><img src="../img/customer-support-icon.svg" alt="icon" />
                                    </span>
                                    <h5 className="bar">Online Chat</h5>
                                    <div className="subtext-phone">
                                        <a className="red-bdr-btn" href="https://home-c36.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=6accbedb-e39c-481b-af3e-bb85d28c9085&bu=4601066" target='_blank'>Chat with representative</a>
                                        <p>Monday-Friday, 8:00 AM to 4:00 PM EST</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<!-- contact-box block section ends here -->*/}
                {/*<!-- contact-form block section starts here -->*/}
                <section className="contact-form-blk">
                    <div className='container'>
                        <div className="row justify-content-center contact-form-row">
                            <div className="col-lg-12">
                                <div className="form-heading">
                                    <h3 className="mb-3">For any issues or concerns, please complete the form below.</h3>
                                    <p>Your information will be sent to us and one of our Customer Care Representatives will contact you shortly.</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row p-0">
                                        <div className="col-md-12 col-12">
                                            <label htmlFor="account-number" className="form-label">Account Number</label>
                                            {/* <input type="text" className="form-control" id="account-number" name="account-number" placeholder="Account Number" required /> */}
                                            <Form.Control
                                                id="account_number"
                                                name="account_number"
                                                type="text"
                                                placeholder="Account Number"
                                                onChange={handleChange}
                                                value={values.account_number}
                                                isValid={touched.account_number && !errors.account_number}
                                                isInvalid={touched.account_number && !!errors.account_number}
                                            />
                                            {touched.account_number && errors.account_number ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.account_number}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="first-name" className="form-label">First Name</label>
                                            <Form.Control
                                                id="first_name"
                                                name="first_name"
                                                type="text"
                                                placeholder="First Name *"
                                                onChange={handleChange}
                                                value={values.first_name}
                                                isValid={touched.first_name && !errors.first_name}
                                                isInvalid={touched.first_name && !!errors.first_name}
                                            />
                                            {touched.first_name && errors.first_name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.first_name}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="last-name" className="form-label">Last Name</label>
                                            <Form.Control
                                                id="last_name"
                                                name="last_name"
                                                type="text"
                                                placeholder="Last Name *"
                                                onChange={handleChange}
                                                value={values.last_name}
                                                isValid={touched.last_name && !errors.last_name}
                                                isInvalid={touched.last_name && !!errors.last_name}
                                            />
                                            {touched.last_name && errors.last_name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.last_name}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <Form.Control
                                                id="address"
                                                name="address"
                                                type="text"
                                                placeholder="Address *"
                                                onChange={handleChange}
                                                value={values.address}
                                                isValid={touched.address && !errors.address}
                                                isInvalid={touched.address && !!errors.address}
                                            />
                                            {touched.address && errors.address ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <label htmlFor="apt-suite" className="form-label">Apt/Suite</label>
                                            <Form.Control
                                                id="apt_suite"
                                                name="apt_suite"
                                                type="text"
                                                placeholder="Apt/Suite"
                                                onChange={handleChange}
                                                value={values.apt_suite}
                                                isValid={touched.apt_suite && !errors.apt_suite}
                                                isInvalid={touched.apt_suite && !!errors.apt_suite}
                                            />
                                            {touched.apt_suite && errors.apt_suite ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.apt_suite}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <label htmlFor="zip" className="form-label">Zip</label>
                                            <Form.Control
                                                id="zip"
                                                name="zip"
                                                type="text"
                                                placeholder="ZIP *"
                                                onChange={handleChange}
                                                value={values.zip}
                                                isValid={touched.zip && !errors.zip}
                                                isInvalid={touched.zip && !!errors.zip}
                                            />
                                            {touched.zip && errors.zip ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.zip}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Form.Control
                                                id="email"
                                                name="email"
                                                type="text"
                                                placeholder="Email *"
                                                onChange={handleChange}
                                                value={values.email}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={touched.email && !!errors.email}
                                            />
                                            {touched.email && errors.email ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            ) : ''}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <label htmlFor="phone-number" className="form-label">Phone Number</label>
                                            <Form.Group>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    placeholder="Enter phone number"
                                                    id="phone_number"
                                                    name="phone_number"
                                                    onChange={handleChange}
                                                    value={values.phone_number}
                                                >
                                                    {inputProps => <Form.Control {...inputProps} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && !!errors.phone_number} />}
                                                </InputMask>
                                                <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <div className="form-group">
                                                <label>Service Type</label>
                                                <select id="serviceType" className="form-control" name="serviceType" onChange={handleChange} value={values.serviceType}>
                                                    <option disabled selected value="">Select your product or service type</option>
                                                    <option value="Home Security">Home Security</option>
                                                    <option value="Oil Heating">Oil Heating</option>
                                                    <option value="Gas Heating">Gas Heating</option>
                                                    <option value="Home Cooling">Home Cooling</option>
                                                    <option value="Commercial Security">Commercial Security</option>
                                                    <option value="Online Account Management">Online Account Management</option>
                                                </select>
                                                {errors.serviceType ? (
                                                    <div className='validation-error'>
                                                        {errors.serviceType}
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="additional-detils" className="form-label">Additional Details</label>
                                            <Form.Control as="textarea"
                                                id="details"
                                                name="details"
                                                rows={3}
                                                placeholder="Additional details"
                                                onChange={handleChange}
                                                value={values.details}
                                            />
                                            {errors.details ? (
                                                <div className='validation-error'>
                                                    {errors.details}
                                                </div>
                                            ) : ''}
                                        </div>
                                        <div className="col-12">
                                            <div className="row sb_btn_wrap">
                                                <div className="col-12">
                                                    <div className="privacy_note">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value={values.terms_of_use} name="terms_of_use" onChange={handleChange} id="flexCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexCheckDefault">You expressly agree to receive informational, emergency, marketing, and promotional text messages by or on behalf of SLOMIN'S, including via automated technology, at the telephone number provided above. Consent not required to make a purchase and you may revoke this consent at any time by texting STOP. Msg & data rates apply. You also agree to our <a href="/privacy-policy" target='_blank'>Privacy Policy</a> and <a href="/term-of-use" target='_blank'>Terms of Use</a>.</label>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="red_btn" disabled={isLoading ? "disabled" : ""}> {isLoading ? "Submitting..." : "Submit Mail"}</button>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <SubscribeCompoents />
            </main>
        </>
    );
};

export default ContactUs;
