import React, { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { submitResidentialForm } from '../../services/get_a_quote';
import { Loader } from '@components';
import InputMask from 'react-input-mask';
import { constant } from '@app/utils/constant';
import Cookies from 'js-cookie';
const Residential = (props: any) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const aoi = (searchParams.size > 0) ? searchParams.get('aoi') : "";
  if (aoi === '04') {
    window.location.href = constant.AC_SITEURL;
  }
  let newValueAOI = aoi ? aoi : "";
  if (newValueAOI !== "" && newValueAOI.charAt(0) === '0') {
    newValueAOI = newValueAOI.slice(1);
  }
  const [initialValuesBefor, setInitialValuesBefor] = useState({ first_name: '', last_name: '', address: '', apt_suite: '', zip: '', email: '', phone_number: '', areaofinterest: '', details: '', aptdate: '', apttime: '', subsource: '', howdidyouhear: "", terms_of_use: "" });
  const [isLoading, setLoading] = useState(false);
  const [optionResidentialAC, setOptionResidentialAC] = useState('');
  const [preferredDateOptionPreD, setPreferredDateOptionPreD] = useState('');
  const [preferredTimeOptionPreD, setPreferredTimeOptionPreD] = useState('');
  const [residentialOption, setResidentialOption] = useState([{ value: '', label: 'Area of Interest *' }, { value: '04', label: 'A/C' }, { value: '11', label: 'Alarm' }, { value: '01', label: 'Oil' }, { value: '10', label: 'Gas' }, { value: '17', label: 'Commercial Security' }]);
  const [preferredTimeOption, setPreferredTimeOption] = useState([{ value: '', label: 'Select Preferred Time' }, { value: '09:00', label: '09:00 AM' }, { value: '09:30', label: '09:30 AM' }, { value: '10:00', label: '10:30 AM' }, {
    value: '11:00', label: '11:00 AM'
  }, { value: '11:30', label: '11:30 AM' }, { value: '12:00', label: '12:00 PM' }, { value: '12:30', label: '12:30 PM' }, { value: '13:00', label: '01:00 PM' }, { value: '13:30', label: '01:30 PM' }, {
    value: '14:00', label: '02:00 PM'
  },
  {
    value: '14:30',
    label: '02:30 PM'
  },
  {
    value: '15:00',
    label: '03:00 PM'
  },
  {
    value: '15:30',
    label: '03:30 PM'
  },
  {
    value: '16:00',
    label: '04:00 PM'
  },
  {
    value: '16:30',
    label: '04:30 PM'
  },
  {
    value: '17:00',
    label: '05:00 PM'
  },
  {
    value: '17:30',
    label: '05:30 PM'
  },
  {
    value: '18:00',
    label: '06:00 PM'
  },
  {
    value: '18:30',
    label: '06:30 PM'
  },
  {
    value: '19:00',
    label: '07:00 PM'
  },
  {
    value: '19:30',
    label: '07:30 PM'
  },
  {
    value: '20:00',
    label: '08:00 PM'
  },
  {
    value: '20:30',
    label: '08:30 PM'
  },
  {
    value: '21:00',
    label: '09:00 PM'
  }
  ]);

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: initialValuesBefor,
    validationSchema: Yup.object({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      apt_suite: Yup.string(),
      zip: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone_number: Yup.string().matches(/^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number format').required('Required'),
      aptdate: Yup.string().required('Required'),
      apttime: Yup.string().required('Required')
    }),
    onSubmit: (values, { resetForm }) => {
      const selectedOptionElement = document.getElementById('mySelect');
      const selectedIndex = selectedOptionElement.selectedIndex;
      const selectedOption = selectedOptionElement.options[selectedIndex];
      const createNewOBJ = {
        ...values,
        subsource: selectedOption.getAttribute('data-subsource')
      };
      setLoading(true);
      // submit form 
      const newObjValue = {
        "AppNamespace": null,
        "AppSid": "",
        "AppVersion": "",
        "CheckVersion": null,
        "IpAddress": null,
        "Latitude": null,
        "Longitude": null,
        "AccountNumber": null,
        "Address1": values.address,
        "Address2": (values.apt_suite) ? values.apt_suite : null,
        "ApptDate": values.aptdate,
        "ApptTime": values.apttime,
        "AreaOfInterest": values.areaofinterest,
        "Caller": "requestsite",
        "City": null,
        "Comment": values.details,
        "Email": values.email,
        "EntryUser": null,
        "FirstName": values.first_name,
        "Keyword": Cookies.get('kw') ? Cookies.get('kw') : null,
        "LastName": values.last_name,
        "LeadSource": values.howdidyouhear,
        "LedgerType": null,
        "Phone1": values.phone_number,
        "Phone2": null,
        "Pid": Cookies.get('pid') ? Cookies.get('pid') : null,
        "PromoCode": null,
        "ReferSource": null,
        "ResCom": "",
        "SalesRep": null,
        "SecurityIssue": null,
        "State": "",
        "SubSource": createNewOBJ.subsource,
        "WebTrack": null,
        "Xid": null,
        "Zip": values.zip,
        "AllowCallPermission": values.terms_of_use ? values.terms_of_use : false
      };
      submitResidentialForm(newObjValue).then(function (res) {
        const resp = res.data;
        setLoading(false);
        if (resp.Acknowledge >= 0) {
          navigate('/thank-you');
        } else {
          toast.error(resp.ErrorDesc);
        }
      }).catch(err => {
        toast.error('Internal Server Error!' + err);
      });
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Control
                id="first_name"
                name="first_name"
                type="text"
                placeholder="First Name *"
                onChange={handleChange}
                value={values.first_name}
                isValid={touched.first_name && !errors.first_name}
                isInvalid={touched.first_name && !!errors.first_name}
              />
              {touched.first_name && errors.first_name ? (
                <Form.Control.Feedback type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              ) : ''}
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Control
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Last Name *"
                onChange={handleChange}
                value={values.last_name}
                isValid={touched.last_name && !errors.last_name}
                isInvalid={touched.last_name && !!errors.last_name}
              />
              {touched.last_name && errors.last_name ? (
                <Form.Control.Feedback type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              ) : ''}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Control
                id="address"
                name="address"
                type="text"
                placeholder="Address *"
                onChange={handleChange}
                value={values.address}
                isValid={touched.address && !errors.address}
                isInvalid={touched.address && !!errors.address}
              />
              {touched.address && errors.address ? (
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              ) : ''}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="input-group">
                  <Form.Control
                    id="apt_suite"
                    name="apt_suite"
                    type="text"
                    placeholder="Apt/Suite"
                    onChange={handleChange}
                    value={values.apt_suite}
                    isValid={touched.apt_suite && !errors.apt_suite}
                    isInvalid={touched.apt_suite && !!errors.apt_suite}
                  />
                  {touched.apt_suite && errors.apt_suite ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.apt_suite}
                    </Form.Control.Feedback>
                  ) : ''}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="input-group">
                  <Form.Control
                    id="zip"
                    name="zip"
                    type="text"
                    placeholder="ZIP *"
                    onChange={handleChange}
                    value={values.zip}
                    isValid={touched.zip && !errors.zip}
                    isInvalid={touched.zip && !!errors.zip}
                  />
                  {touched.zip && errors.zip ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.zip}
                    </Form.Control.Feedback>
                  ) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Control
                id="email"
                name="email"
                type="text"
                placeholder="Email *"
                onChange={handleChange}
                value={values.email}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />
              {touched.email && errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : ''}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Group>
                <InputMask
                  mask="(999) 999-9999"
                  placeholder="Enter phone number"
                  id="phone_number"
                  name="phone_number"
                  onChange={handleChange}
                  value={values.phone_number}
                >
                  {inputProps => <Form.Control {...inputProps} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && !!errors.phone_number} />}
                </InputMask>
                <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="input-group">
              <Form.Group className={`col-md-12 ${errors.areaofinterest ? 'select-validation-error' : ''}`} controlId="areaofinterest">
                <Select
                  onChange={
                    selectedOption => {
                      handleChange({ target: { name: 'areaofinterest', value: selectedOption.value } });
                      setOptionResidentialAC(selectedOption.value);
                    }}
                  placeholder="Select option"
                  value={optionResidentialAC ? residentialOption[optionResidentialAC] : newValueAOI ? residentialOption[newValueAOI] : ''}
                  name="areaofinterest"
                  options={residentialOption}
                />
              </Form.Group>
              {errors.areaofinterest ? (
                <div className='validation-error'>
                  {errors.areaofinterest}
                </div>
              ) : ''}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <div className="input-group">
              <Form.Control as="textarea"
                id="details"
                name="details"
                rows={3}
                placeholder="Additional details"
                onChange={handleChange}
                value={values.details}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Group className={`col-md-12 ${errors.aptdate ? 'select-validation-error' : ''}`} controlId="aptdate">
                <Select
                  onChange={
                    selectedOption => {
                      handleChange({ target: { name: 'aptdate', value: selectedOption.value } });
                      setPreferredDateOptionPreD(selectedOption.value);
                    }}
                  placeholder="Select preferred date *"
                  value={preferredDateOptionPreD ? props.appointDate[preferredDateOptionPreD] : ''}
                  name="aptdate"
                  options={props.appointDate}
                />
                {errors.aptdate ? (
                  <div className='validation-error'>
                    {errors.aptdate}
                  </div>
                ) : ''}
              </Form.Group>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group">
              <Form.Group className={`col-md-12 ${errors.apttime ? 'select-validation-error' : ''}`} controlId="apttime">
                <Select
                  onChange={
                    selectedOption => {
                      handleChange({ target: { name: 'apttime', value: selectedOption.value } });
                      setPreferredTimeOptionPreD(selectedOption.value);
                    }}
                  placeholder="Select preferred time *"
                  value={preferredTimeOptionPreD ? preferredTimeOption[preferredTimeOptionPreD] : ''}
                  name="apttime"
                  options={preferredTimeOption}
                />
                {errors.apttime ? (
                  <div className='validation-error is-invalid'>
                    {errors.apttime}
                  </div>
                ) : ''}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="input-group">
              <select id="mySelect" className="form-control" name="howdidyouhear" onChange={handleChange} value={values.howdidyouhear}>
                <option value="">How did you hear about us? *</option>
                <option value="034" data-subsource="002">Internet</option>
                <option value="004" data-subsource="886">TV</option>
                <option value="001" data-subsource="886">Newspaper</option>
                <option value="003" data-subsource="886">Radio</option>
                <option value="008" data-subsource="886">Friend</option>
                <option value="034" data-subsource="013">Other</option>
              </select>
              {errors.subsource ? (
                <div className='validation-error'>
                  {errors.subsource}
                </div>
              ) : ''}
            </div>
          </div>
        </div>
        <div className="submit_btn_blk">
          <div className="privacy_note">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value={values.terms_of_use} name="terms_of_use" onChange={handleChange} id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">You expressly agree to receive informational, emergency, marketing, and promotional text messages by or on behalf of SLOMIN’S, including via automated technology, at the telephone number provided above. Consent not required to make a purchase and you may revoke this consent at any time by texting STOP. Msg & data rates apply. You also agree to our <a href="/privacy-policy" target='_blank'>Privacy Policy</a> and <a href="/term-of-use" target='_blank'>Terms of Use</a>.</label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary red_btn" disabled={isLoading ? "disabled" : ""}> {isLoading ? "Submitting..." : "Submit Your Request"}</button>
        </div>
      </form>
      <Loader isLoading={isLoading} />
    </>
  );
};
export default Residential;